/**
 *      Utilitário para verificar a autenticação
 */
import { getLocalData } from "@/pages/auth/storage";

export const authUtils = { 

    isAdmin: () => { 
        let user = getLocalData().user;
        return user.roles.find((e) => e === "admin") == "admin" ? true : false;
    },

    isStudent: () => {
        var user = getLocalData().user;
        return user.roles.find((e) => e === "student") == "student" ? true : false;
    },

    getUser: () => {
        return getLocalData().user;
    }
}