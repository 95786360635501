/**
 *      Utilitário para formatar as datas
 */
export const formatDate = {
    dmy: (ts) => {
      if (ts === null) return ''
      var dd = new Date(ts.toString())
      dd = (dd.getDate() < 9 ? '0' : '') + dd.getDate() + '/' + (dd.getMonth() < 9 ? '0' : '') + (dd.getMonth() + 1) + '/' + dd.getFullYear() // dd/mm/yyyy
      return dd
    },
    dmyh: (ts) => {
      if (ts === null || ts === undefined) return ''
      var dd = new Date(ts.toString())
      dd = (dd.getDate() < 9 ? '0' : '') + dd.getDate() + '/' + (dd.getMonth() < 9 ? '0' : '') + (dd.getMonth() + 1) + '/' + dd.getFullYear() + ' ' + (dd.getHours() < 10? '0'+dd.getHours(): dd.getHours()) + ':' + (dd.getMinutes() < 10? '0'+dd.getMinutes(): dd.getMinutes()); // dd/mm/yyyy hh:mm
      return dd
    },
    ymd: (ts) => {
      if (ts === null) return ''
      var dd = new Date(ts.toString())
      dd = dd.getFullYear() + '-' + (dd.getMonth() < 9 ? '0' : '') + (dd.getMonth() + 1) + '-' + (dd.getDate() < 9 ? '0' : '') + dd.getDate() // yyyy-mm-dd
      return dd
    },
    ys: (ts) => {
      if (ts === null) return ''
      var dd = new Date(ts.toString())
      dd = dd.getFullYear() + '/' + (dd.getMonth() < 6 ? 1 : 2) + 's' // yyyy/SSs
      return dd
    },
    getDayOfWeek: (number) => {
      switch (number){
        case 0:
          return " ";
        case 1:
          return " ";
        case 2:
          return "Segunda";
        case 3:
          return "Terça";
        case 4:
          return "Quarta";
        case 5:
          return "Quinta";
        case 6: 
          return "Sexta";
        case 7:
          return "Sábado"
        default:
          return ""              
      }
    }
  }
  